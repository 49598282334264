import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useWindowSize } from '@react-hook/window-size'

import { DeviceModel, Device, Variant } from '../../types'
import devices from '../../devices'
import TopBar from '../TopBar/TopBar'
import DeviceSelection from '../DeviceSelection/DeviceSelection'
import DeviceCanvas from '../DeviceCanvas/DeviceCanvas'
import WhiteBackground from '../WhiteBackground/WhiteBackground'
import Dropzone from '../Dropzone/Dropzone'
import Loading from '../Loading/Loading'
import ToolBar from '../ToolBar/ToolBar'

const useStyles = makeStyles({
	root: {
		display: 'flex',
		overflowY: 'auto',
		overflowX: 'auto',
		position: 'absolute',
		backgroundColor: '#f6f6f6',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	content: {
		flexGrow: 1,
		width: '100%'
	},
	deviceSelectionContainer: {
		width: '100%',
		padding: '8px'
	},
	deviceContainer: {
	}
})

const App: React.FC = () => {
	const classes = useStyles()
	const { deviceKey } = useParams<{ deviceKey: DeviceModel }>()
	const [device, setDevice] = useState({} as Device)
	const [variant, setVariant] = useState({} as Variant)
	const [file, setFile] = useState<any>(null)
	const [zoom, setZoom] = useState<number>(1)
	const [rotated, setRotated] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [windowWidth, windowHeight] = useWindowSize()
	const canvasRef = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		const newDevice = devices.get(deviceKey || DeviceModel.AppleIPhone11) as Device
		setDevice(newDevice)
		setVariant(newDevice.variants[0])
		if (!newDevice.changeOrientation) {
			setRotated(false)
		}
	}, [deviceKey])

	if (!device.key) {
		return null
	}

	const maxDeviceHeight = windowHeight * 0.7
	const maxDeviceWidth = windowWidth * 0.9

	let { deviceHeight, deviceWidth } = device
	deviceHeight *= zoom
	deviceWidth *= zoom
	if (rotated) {
		deviceHeight = device.deviceWidth * zoom
		deviceWidth = device.deviceHeight * zoom
	}

	let aspectRatio = 1
	// Portrait type device
	if ((deviceHeight * aspectRatio) <= maxDeviceHeight) {
		// Device fits on height, keep current aspect ratio
	}
	if ((deviceHeight * aspectRatio) > maxDeviceHeight) {
		// Device does not fit on height, adjust aspect ratio
		aspectRatio *= maxDeviceHeight / (deviceHeight * aspectRatio)
	}
	if ((deviceWidth * aspectRatio) <= maxDeviceWidth) {
		// Device fits on width, keep current aspect ratio
	}
	if ((deviceWidth * aspectRatio) > maxDeviceWidth) {
		// Device does not fit on width, adjust aspect ratio
		aspectRatio *= maxDeviceWidth / (deviceWidth * aspectRatio)
	}
	// aspectRatio = 1 // uncomment for full size

	const containerStyles = {
		width: deviceWidth * aspectRatio,
		height: deviceHeight * aspectRatio
	}

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<TopBar />
				<Grid container direction="column" alignItems="center">
					<Grid className={classes.deviceSelectionContainer} container item xs={11} md={9} lg={7} justify="center">
						<DeviceSelection />
					</Grid>
					<Grid item>
						<div className={classes.deviceContainer} style={containerStyles}>
							<Dropzone device={device} aspectRatio={aspectRatio} zoom={zoom} file={file} setFile={setFile} rotated={rotated} isLoading={isLoading} />
							<DeviceCanvas key={`${device.key}-${rotated ? 'landscape' : 'portrait'}`} canvasRef={canvasRef} device={device} variant={variant} aspectRatio={aspectRatio} zoom={zoom} file={file} rotated={rotated} isLoading={isLoading} setIsLoading={setIsLoading} />
							<WhiteBackground device={device} aspectRatio={aspectRatio} zoom={zoom} file={file} rotated={rotated} isLoading={isLoading} />
							<Loading device={device} aspectRatio={aspectRatio} zoom={zoom} rotated={rotated} isLoading={isLoading} />
						</div>
					</Grid>
					<ToolBar
						canvasRef={canvasRef}
						device={device}
						variant={variant}
						setVariant={setVariant}
						zoom={zoom}
						setZoom={setZoom}
						setFile={setFile}
						rotated={rotated}
						setRotated={setRotated}
					/>
					<Grid container item xs={11} md={9} lg={7} justify="center" spacing={1}>
						<Grid item>
							<Typography color="textSecondary" variant="caption">Credits for all devices go to <a href="https://facebook.design/devices" target="_blank" rel="noopener noreferrer">https://facebook.design/devices</a></Typography>
						</Grid>
						<Grid item style={{ textAlign: 'center' }}>
							<Typography color="textSecondary" variant="caption">The third party designs appearing on this website are the intellectual property of their respective owners. By accessing these third party design assets, you agree to obtain all necessary permissions from the underlying rights holders and/or adhere to any applicable brand use guidelines before using them. MockUpIfy disclaims all express or implied warranties with respect to these assets, including non-infringement of intellectual property rights.</Typography>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

export default App
