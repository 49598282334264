import { DeviceModel, Device } from '../types'

const SonyW850C: Device = {
	key: DeviceModel.SonyW850C,
	brand: 'Sony',
	model: 'W850C',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 823,
	deviceWidth: 1307,
	screenHeight: 720,
	screenWidth: 1280,
	screenTopOffset: 22,
	screenLeftOffset: 12,
	variants: [
		{ key: 'default', description: '', src: 'devices/sony-w850c/sony-w850c.png' }
	]
}

export default SonyW850C
