import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Device } from '../../types'

const useStyles = makeStyles({
	screen: {
		position: 'absolute',
		zIndex: 1,
		backgroundColor: '#fff'
	}
})

interface WhiteBackgroundProps {
	device: Device,
	aspectRatio: number,
	zoom: number,
	file: any,
	rotated: boolean,
	isLoading: boolean
}

const WhiteBackground: React.FC<WhiteBackgroundProps> = ({ device, aspectRatio, zoom, file, rotated, isLoading }) => {
	const classes = useStyles()

	if (file || isLoading) {
		return null
	}

	let { screenWidth, screenHeight, screenTopOffset, screenLeftOffset } = device
	screenWidth *= zoom
	screenHeight *= zoom
	screenTopOffset *= zoom
	screenLeftOffset *= zoom
	if (rotated) {
		screenWidth = device.screenHeight * zoom
		screenHeight = device.screenWidth * zoom
		screenTopOffset = device.screenLeftOffset * zoom
		screenLeftOffset = device.screenTopOffset * zoom
	}

	const screenStyles = {
		width: screenWidth * aspectRatio,
		height: screenHeight * aspectRatio,
		marginTop: screenTopOffset * aspectRatio,
		marginLeft: screenLeftOffset * aspectRatio
	}

	return <div className={classes.screen} style={screenStyles}></div>
}

export default WhiteBackground
