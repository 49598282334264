import React, { useRef, useState, useCallback, RefObject } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import { Device, Variant } from '../../types'

const useStyles = makeStyles({
	toolBar: {
		padding: '8px'
	},
	popper: {
		position: 'absolute',
		zIndex: 4
	}
})

interface ToolBarProps {
	canvasRef: RefObject<HTMLCanvasElement>,
	device: Device,
	variant: Variant,
	setVariant: (variant: Variant) => void,
	zoom: number,
	setZoom: (zoom: number) => void,
	setFile: (file: any) => void,
	rotated: boolean,
	setRotated: (rotated: boolean) => void
}

const ToolBar: React.FC<ToolBarProps> = ({ canvasRef, device, variant, setVariant, zoom, setZoom, setFile, rotated, setRotated }) => {
	const classes = useStyles()
	const [variantsOpen, setVariantsOpen] = useState<boolean>(false)
	const variantsRef = useRef(null)

	const handleDownload = useCallback(() => {
		const a = document.createElement('a')
		a.href = canvasRef?.current?.toDataURL('image/pngbase64') || ''
		a.download = `${device.key}.png`
		document.body.appendChild(a)
		a.click()
		a.remove()
	}, [canvasRef, device])

	const handleClear = useCallback(() => {
		setFile(null)
	}, [setFile])

	const handleTurn = useCallback(() => {
		setRotated(!rotated)
	}, [rotated, setRotated])

	let turnButton
	if (device.changeOrientation) {
		turnButton = (
			<Grid item xs={6} sm={3}>
				<Button disableElevation fullWidth onClick={handleTurn} variant="contained" color="primary" startIcon={<ScreenRotationIcon />}>Rotate</Button>
			</Grid>
		)
	}

	let variants
	if (device.variants && device.variants.length > 1) {
		variants = (
			<Grid item xs={6} sm={3}>
				<Button disableElevation fullWidth onClick={(): void => setVariantsOpen(true)} ref={variantsRef} variant="contained" color="primary" endIcon={<ArrowDropDownIcon />}>{variant.description}</Button>
				<Popper className={classes.popper} open={variantsOpen} anchorEl={variantsRef.current} role={undefined} transition disablePortal>
					{({ TransitionProps, placement }): JSX.Element => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
							}}>
							<Paper>
								<ClickAwayListener onClickAway={(): void => setVariantsOpen(false)}>
									<MenuList id="split-button-menu">
										{device.variants.map(v => (
											<MenuItem
												key={v.key}
												selected={variant.key === v.key}
												onClick={(): void => {
													setVariant(v)
												}}>
												{v.description}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Grid>
		)
	}

	return (
		<>
			<Grid className={classes.toolBar} container item xs={11} md={9} lg={7} justify="center" spacing={2}>
				<Grid item xs={12}>
					<ButtonGroup fullWidth variant="contained" color="default" disableElevation>
						<Button disabled={zoom === 0.25} onClick={(): void => setZoom(0.25)}>25%</Button>
						<Button disabled={zoom === 0.5} onClick={(): void => setZoom(0.5)}>50%</Button>
						<Button disabled={zoom === 0.75} onClick={(): void => setZoom(0.75)}>75%</Button>
						<Button disabled={zoom === 1} onClick={(): void => setZoom(1)}>100%</Button>
					</ButtonGroup>
				</Grid>
			</Grid>
			<Grid className={classes.toolBar} container item xs={11} md={9} lg={7} justify="center" spacing={2}>
				{variants}
				{turnButton}
				<Grid item xs={6} sm={3}>
					<Button disableElevation fullWidth onClick={handleClear} variant="contained" color="primary" startIcon={<DeleteIcon />}>Clear</Button>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Button disableElevation fullWidth onClick={handleDownload} variant="contained" color="primary" startIcon={<SaveIcon />}>Download</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default ToolBar
