import { DeviceModel, Device } from '../types'

const AppleIPhone11Pro: Device = {
	key: DeviceModel.AppleIPhone11Pro,
	brand: 'Apple',
	model: 'iPhone 11 Pro',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2696,
	deviceWidth: 1385,
	screenHeight: 2436,
	screenWidth: 1125,
	screenTopOffset: 130,
	screenLeftOffset: 130,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-iphone-11-pro/apple-iphone-11-pro-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-iphone-11-pro/apple-iphone-11-pro-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-iphone-11-pro/apple-iphone-11-pro-gold.png' },
		{ key: 'midnight-green', description: 'Midnight Green', src: 'devices/apple-iphone-11-pro/apple-iphone-11-pro-midnight-green.png' }
	]
}

export default AppleIPhone11Pro
