import { DeviceModel, Device } from '../types'

const AppleIPadPro13WithPencil: Device = {
	key: DeviceModel.AppleIPadPro13WithPencil,
	brand: 'Apple',
	model: 'iPad Pro 13" (with pencil)',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 3132,
	deviceWidth: 2448,
	screenHeight: 2732,
	screenWidth: 2048,
	screenTopOffset: 200,
	screenLeftOffset: 200,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad-pro-13-with-pencil/apple-ipad-pro-13-with-pencil-space-gray.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad-pro-13-with-pencil/apple-ipad-pro-13-with-pencil-silver.png' }
	]
}

export default AppleIPadPro13WithPencil
