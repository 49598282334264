import { DeviceModel, Device } from '../types'

const AppleProDisplayXDR: Device = {
	key: DeviceModel.AppleProDisplayXDR,
	brand: 'Apple',
	model: 'Pro Display XDR',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 4765,
	deviceWidth: 6316,
	screenHeight: 3384,
	screenWidth: 6016,
	screenTopOffset: 150,
	screenLeftOffset: 150,
	variants: [
		{ key: 'default', description: '', src: 'devices/apple-pro-display-xdr/apple-pro-display-xdr.png' }
	]
}

export default AppleProDisplayXDR
