import { DeviceModel, Device } from '../types'

const AppleIPhone11: Device = {
	key: DeviceModel.AppleIPhone11,
	brand: 'Apple',
	model: 'iPhone 11',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 1992,
	deviceWidth: 1028,
	screenHeight: 1792,
	screenWidth: 828,
	screenTopOffset: 100,
	screenLeftOffset: 100,
	variants: [
		{ key: 'black', description: 'Black', src: 'devices/apple-iphone-11/apple-iphone-11-black.png' },
		{ key: 'green', description: 'Green', src: 'devices/apple-iphone-11/apple-iphone-11-green.png' },
		{ key: 'purple', description: 'Purple', src: 'devices/apple-iphone-11/apple-iphone-11-purple.png' },
		{ key: 'red', description: 'Red', src: 'devices/apple-iphone-11/apple-iphone-11-red.png' },
		{ key: 'white', description: 'White', src: 'devices/apple-iphone-11/apple-iphone-11-white.png' },
		{ key: 'yellow', description: 'Yellow', src: 'devices/apple-iphone-11/apple-iphone-11-yellow.png' }
	]
}

export default AppleIPhone11
