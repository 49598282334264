import { DeviceModel, Device } from '../types'

const AppleIPadAir: Device = {
	key: DeviceModel.AppleIPadAir,
	brand: 'Apple',
	model: 'iPad Air',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2748,
	deviceWidth: 1946,
	screenHeight: 2224,
	screenWidth: 1668,
	screenTopOffset: 262,
	screenLeftOffset: 139,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad-air/apple-ipad-air-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad-air/apple-ipad-air-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-ipad-air/apple-ipad-air-gold.png' }
	]
}

export default AppleIPadAir
