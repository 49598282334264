import { DeviceModel, Device } from '../types'

const DellUltraSharp: Device = {
	key: DeviceModel.DellUltraSharp,
	brand: 'Dell',
	model: 'UltraSharp',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2099,
	deviceWidth: 2626,
	screenHeight: 1440,
	screenWidth: 2560,
	screenTopOffset: 59,
	screenLeftOffset: 33,
	variants: [
		{ key: 'default', description: '', src: 'devices/dell-ultrasharp/dell-ultrasharp.png' }
	]
}

export default DellUltraSharp
