import { DeviceModel, Device } from '../types'

const DellXPS13: Device = {
	key: DeviceModel.DellXPS13,
	brand: 'Dell',
	model: 'XPS 13',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2256,
	deviceWidth: 3834,
	screenHeight: 1800,
	screenWidth: 3200,
	screenTopOffset: 86,
	screenLeftOffset: 317,
	variants: [
		{ key: 'default', description: '', src: 'devices/dell-xps-13/dell-xps-13.png' }
	]
}

export default DellXPS13
