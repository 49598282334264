import { DeviceModel, Device } from '../types'
import AppleIMac from './apple-imac'
import AppleIMacPro from './apple-imac-pro'
import AppleIPad from './apple-ipad'
import AppleIPadAir from './apple-ipad-air'
import AppleIPadMini from './apple-ipad-mini'
import AppleIPadPro11WithoutPencil from './apple-ipad-pro-11-without-pencil'
import AppleIPadPro11WithPencil from './apple-ipad-pro-11-with-pencil'
import AppleIPadPro13WithoutPencil from './apple-ipad-pro-13-without-pencil'
import AppleIPadPro13WithPencil from './apple-ipad-pro-13-with-pencil'
import AppleIPhone11 from './apple-iphone-11'
import AppleIPhone11Pro from './apple-iphone-11-pro'
import AppleIPhone11ProMax from './apple-iphone-11-pro-max'
import AppleIPhone8 from './apple-iphone-8'
import AppleIPhone8Plus from './apple-iphone-8-plus'
import AppleMacBook from './apple-macbook'
import AppleMacBookAir from './apple-macbook-air'
import AppleMacBookPro13 from './apple-macbook-pro-13'
import AppleMacBookPro16 from './apple-macbook-pro-16'
import AppleProDisplayXDR from './apple-pro-display-xdr'
import AppleThunderboltDisplay from './apple-thunderbolt-display'
import AppleWatchSeries540mm from './apple-watch-series-5-40mm'
import AppleWatchSeries544mm from './apple-watch-series-5-44mm'
import DellUltraSharp from './dell-ultrasharp'
import DellXPS13 from './dell-xps-13'
import DellXPS15 from './dell-xps-15'
import MicrosoftLumia950 from './microsoft-lumia-950'
import MicrosoftSurfaceBook from './microsoft-surface-book'
import MicrosoftSurfacePro4 from './microsoft-surface-pro-4'
import SamsungGalaxyS10 from './samsung-galaxy-s10'
import SamsungGalaxyS20 from './samsung-galaxy-s20'
import SamsungGalaxyS20Ultra from './samsung-galaxy-s20-ultra'
import SonySmartWatch3 from './sony-smartwatch-3'
import SonyW850C from './sony-w850c'

const devices: Map<DeviceModel, Device> = new Map()
devices.set(DeviceModel.AppleIMac, AppleIMac)
devices.set(DeviceModel.AppleIMacPro, AppleIMacPro)
devices.set(DeviceModel.AppleIPad, AppleIPad)
devices.set(DeviceModel.AppleIPadAir, AppleIPadAir)
devices.set(DeviceModel.AppleIPadMini, AppleIPadMini)
devices.set(DeviceModel.AppleIPadPro11WithoutPencil, AppleIPadPro11WithoutPencil)
devices.set(DeviceModel.AppleIPadPro11WithPencil, AppleIPadPro11WithPencil)
devices.set(DeviceModel.AppleIPadPro13WithoutPencil, AppleIPadPro13WithoutPencil)
devices.set(DeviceModel.AppleIPadPro13WithPencil, AppleIPadPro13WithPencil)
devices.set(DeviceModel.AppleIPhone11, AppleIPhone11)
devices.set(DeviceModel.AppleIPhone11Pro, AppleIPhone11Pro)
devices.set(DeviceModel.AppleIPhone11ProMax, AppleIPhone11ProMax)
devices.set(DeviceModel.AppleIPhone8, AppleIPhone8)
devices.set(DeviceModel.AppleIPhone8Plus, AppleIPhone8Plus)
devices.set(DeviceModel.AppleMacBook, AppleMacBook)
devices.set(DeviceModel.AppleMacBookAir, AppleMacBookAir)
devices.set(DeviceModel.AppleMacBookPro13, AppleMacBookPro13)
devices.set(DeviceModel.AppleMacBookPro16, AppleMacBookPro16)
devices.set(DeviceModel.AppleProDisplayXDR, AppleProDisplayXDR)
devices.set(DeviceModel.AppleThunderboltDisplay, AppleThunderboltDisplay)
devices.set(DeviceModel.AppleWatchSeries540mm, AppleWatchSeries540mm)
devices.set(DeviceModel.AppleWatchSeries544mm, AppleWatchSeries544mm)
devices.set(DeviceModel.DellUltraSharp, DellUltraSharp)
devices.set(DeviceModel.DellXPS13, DellXPS13)
devices.set(DeviceModel.DellXPS15, DellXPS15)
devices.set(DeviceModel.MicrosoftLumia950, MicrosoftLumia950)
devices.set(DeviceModel.MicrosoftSurfaceBook, MicrosoftSurfaceBook)
devices.set(DeviceModel.MicrosoftSurfacePro4, MicrosoftSurfacePro4)
devices.set(DeviceModel.SamsungGalaxyS10, SamsungGalaxyS10)
devices.set(DeviceModel.SamsungGalaxyS20, SamsungGalaxyS20)
devices.set(DeviceModel.SamsungGalaxyS20Ultra, SamsungGalaxyS20Ultra)
devices.set(DeviceModel.SonySmartWatch3, SonySmartWatch3)
devices.set(DeviceModel.SonyW850C, SonyW850C)

export default devices
