import { DeviceModel, Device } from '../types'

const AppleIPadPro13WithoutPencil: Device = {
	key: DeviceModel.AppleIPadPro13WithoutPencil,
	brand: 'Apple',
	model: 'iPad Pro 13" (without pencil)',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 3132,
	deviceWidth: 2448,
	screenHeight: 2732,
	screenWidth: 2048,
	screenTopOffset: 200,
	screenLeftOffset: 200,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad-pro-13-without-pencil/apple-ipad-pro-13-without-pencil-space-gray.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad-pro-13-without-pencil/apple-ipad-pro-13-without-pencil-silver.png' }
	]
}

export default AppleIPadPro13WithoutPencil
