import { DeviceModel, Device } from '../types'

const AppleIPhone11ProMax: Device = {
	key: DeviceModel.AppleIPhone11ProMax,
	brand: 'Apple',
	model: 'iPhone 11 Pro Max',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2948,
	deviceWidth: 1502,
	screenHeight: 2688,
	screenWidth: 1242,
	screenTopOffset: 130,
	screenLeftOffset: 130,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-iphone-11-pro-max/apple-iphone-11-pro-max-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-iphone-11-pro-max/apple-iphone-11-pro-max-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-iphone-11-pro-max/apple-iphone-11-pro-max-gold.png' },
		{ key: 'midnight-green', description: 'Midnight Green', src: 'devices/apple-iphone-11-pro-max/apple-iphone-11-pro-max-midnight-green.png' }
	]
}

export default AppleIPhone11ProMax
