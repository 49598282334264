import { DeviceModel, Device } from '../types'

const AppleThunderboltDisplay: Device = {
	key: DeviceModel.AppleThunderboltDisplay,
	brand: 'Apple',
	model: 'Thunderbolt Display ',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2156,
	deviceWidth: 2784,
	screenHeight: 1440,
	screenWidth: 2560,
	screenTopOffset: 130,
	screenLeftOffset: 114,
	variants: [
		{ key: 'default', description: '', src: 'devices/apple-thunderbolt-display/apple-thunderbolt-display.png' }
	]
}

export default AppleThunderboltDisplay
