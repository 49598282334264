import { DeviceModel, Device } from '../types'

const SonySmartWatch3: Device = {
	key: DeviceModel.SonySmartWatch3,
	brand: 'Sony',
	model: 'SmartWatch 3',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 740,
	deviceWidth: 448,
	screenHeight: 320,
	screenWidth: 320,
	screenTopOffset: 188,
	screenLeftOffset: 64,
	variants: [
		{ key: 'black', description: 'Black', src: 'devices/sony-smartwatch-3/sony-smartwatch-3-black.png' },
		{ key: 'white', description: 'White', src: 'devices/sony-smartwatch-3/sony-smartwatch-3-white.png' }
	]
}

export default SonySmartWatch3
