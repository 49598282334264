import { DeviceModel, Device } from '../types'

const AppleWatchSeries540mm: Device = {
	key: DeviceModel.AppleWatchSeries540mm,
	brand: 'Apple',
	model: 'Watch Series 5 40mm',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 914,
	deviceWidth: 524,
	screenHeight: 394,
	screenWidth: 324,
	screenTopOffset: 260,
	screenLeftOffset: 100,
	variants: [
		{ key: 'space-grey-aluminum-black', description: 'Space Grey Aluminum + Black', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-space-grey-aluminum-black.png' },
		{ key: 'gold-aluminum-pink-sand', description: 'Gold Aluminum + Pink Sand', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-gold-aluminum-pink-sand.png' },
		{ key: 'gold-stainless-steel-stone', description: 'Gold Stainless Steel + Stone', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-gold-stainless-steel-stone.png' },
		{ key: 'silver-aluminum-clementine', description: 'Silver Aluminum + Clementine', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-silver-aluminum-clementine.png' },
		{ key: 'space-black-stainless-steel-alaskan-blue', description: 'Space Black Stainless Steel + Alaskan Blue', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-space-black-stainless-steel-alaskan-blue.png' },
		{ key: 'space-black-titanium-pine-green', description: 'Space Black Titanium + Pine Green', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-space-black-titanium-pine-green.png' },
		{ key: 'stainless-steel-lemon-cream', description: 'Stainless Steel + Lemon Cream', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-stainless-steel-lemon-cream.png' },
		{ key: 'titanium-red', description: 'Titanium + Red', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-titanium-red.png' },
		{ key: 'white-ceramic-white', description: 'White Ceramic + White', src: 'devices/apple-watch-series-5-40mm/apple-watch-series-5-40mm-white-ceramic-white.png' }
	]
}

export default AppleWatchSeries540mm
