import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
	canvas: {
		position: 'absolute',
		zIndex: 2
	},
	dropzone: {
		position: 'absolute',
		zIndex: 3,
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		outline: 'none',
		cursor: 'pointer',
		overflow: 'hidden'
	}
})

const Generat0r: React.FC = () => {
	const classes = useStyles()
	const [windowWidth, windowHeight] = useWindowSize()
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const [isLoadingDevice, setIsLoadingDevice] = useState(true)
	const [file, setFile] = useState<any>(null)
	const [deviceImage] = useState(new Image())
	const [deviceSizes, setDeviceSizes] = useState<any>(null)
	const onDrop = useCallback((acceptedFiles) => {
		const [acceptedFile] = acceptedFiles
		acceptedFile.src = URL.createObjectURL(acceptedFile)
		setIsLoadingDevice(true)
		setFile(acceptedFile)
	}, [setFile])
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	useEffect(() => {
		deviceImage.onload = (): void => {
			setIsLoadingDevice(false)
		}
		if (file) {
			deviceImage.src = file.src
		} else {
			setIsLoadingDevice(false)
		}
	}, [file, deviceImage])

	useEffect(() => {
		if (!isLoadingDevice && file) {
			const ctx = canvasRef?.current?.getContext('2d')
			if (ctx) {
				ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
				ctx.drawImage(deviceImage, 0, 0, deviceImage.width, deviceImage.height)

				let screenLeftOffset = deviceImage.width
				let screenRightOffset = 0
				let screenTopOffset = deviceImage.height
				let screenBottomOffset = 0

				const imageData = ctx.getImageData(0, 0, deviceImage.width, deviceImage.height).data

				for (let y = 0; y < deviceImage.height; y += 1) {
					let screenStart
					let screenEnd
					let atBorder = false
					const rowIndex = (y * deviceImage.width * 4)
					for (let x = 0; x < deviceImage.width; x += 1) {
						if (screenStart && screenEnd) {
							break
						}

						const startIndex = rowIndex + (x * 4)
						const alpha = imageData[startIndex + 3]
						if (!atBorder && !screenStart && alpha !== 0) {
							// Left border reached before screen
							atBorder = true
						} else if (alpha === 0 && atBorder && !screenStart) {
							// Screen started after left border
							atBorder = false
							screenStart = { x, y }
						} else if (alpha !== 0 && screenStart && !screenEnd) {
							// Right border reached after screen
							screenEnd = { x, y }
						}
					}
					if (screenStart && screenEnd) {
						if (screenStart.y < screenTopOffset) {
							screenTopOffset = screenStart.y
						}
						if (screenEnd.y > screenBottomOffset) {
							screenBottomOffset = screenEnd.y
						}
						if (screenStart.x < screenLeftOffset) {
							screenLeftOffset = screenStart.x
						}
						if (screenEnd.x > screenRightOffset) {
							screenRightOffset = screenEnd.x
						}
					}
				}
				const screenWidth = screenRightOffset - screenLeftOffset
				const screenHeight = screenBottomOffset - screenTopOffset + 1 // Add 1 because the bottom offset is part of the screen
				setDeviceSizes({ screenTopOffset, screenLeftOffset, screenWidth, screenHeight })
			}
		}
	}, [canvasRef, deviceImage, file, isLoadingDevice])

	if (isLoadingDevice) {
		return null
	}

	const maxDeviceHeight = windowHeight * 0.7
	const maxDeviceWidth = windowWidth * 0.9

	let aspectRatio = 1
	// Portrait type device
	if ((deviceImage.height * aspectRatio) <= maxDeviceHeight) {
		// Device fits on height, keep current aspect ratio
	}
	if ((deviceImage.height * aspectRatio) > maxDeviceHeight) {
		// Device does not fit on height, adjust aspect ratio
		aspectRatio *= maxDeviceHeight / (deviceImage.height * aspectRatio)
	}
	if ((deviceImage.width * aspectRatio) <= maxDeviceWidth) {
		// Device fits on width, keep current aspect ratio
	}
	if ((deviceImage.width * aspectRatio) > maxDeviceWidth) {
		// Device does not fit on width, adjust aspect ratio
		aspectRatio *= maxDeviceWidth / (deviceImage.width * aspectRatio)
	}

	const sizingStyles = {
		width: deviceSizes ? deviceSizes.screenWidth * aspectRatio : '100%',
		height: deviceSizes ? deviceSizes.screenHeight * aspectRatio : '100%',
		marginTop: deviceSizes ? deviceSizes.screenTopOffset * aspectRatio : '0',
		marginLeft: deviceSizes ? deviceSizes.screenLeftOffset * aspectRatio : '0'
	}

	let text
	if (!file) {
		text = (
			<Typography style={{ margin: '8px', textAlign: 'center', width: '100%', overflow: 'hidden', fontSize: '2vh' }} color="textSecondary">
				<p>Drag ‘n’ drop your image, or click here to select</p>
			</Typography>
		)
	}

	if (!file) {
		return (
			<div className={classes.dropzone} style={sizingStyles} {...getRootProps()}>
				<input {...getInputProps({ multiple: false })} />
				{text}
			</div>
		)
	}

	const deviceStyles = {
		width: deviceImage.width * aspectRatio,
		height: deviceImage.height * aspectRatio
	}

	const frameSrc = file.name.toLowerCase().split(' ').join('-')
	const key = file.name.split('.')[0].split(' ').map((part: string) => `${part.charAt(0).toUpperCase()}${part.slice(1)}`).join('')

	const device = `
import { DeviceModel, Device } from '../types'

const ${key}: Device = {
	key: DeviceModel.${key},
	brand: '${file.name.split('.')[0]}',
	model: '${file.name.split('.')[0]}',
	frameSrc: '${frameSrc.split('.')[0]}.${frameSrc.split('.')[1]}',
	searchable: true,
	changeOrientation: true,
	deviceHeight: ${deviceImage.height},
	deviceWidth: ${deviceImage.width},
	screenHeight: ${deviceSizes?.screenHeight},
	screenWidth: ${deviceSizes?.screenWidth},
	screenTopOffset: ${deviceSizes?.screenTopOffset},
	screenLeftOffset: ${deviceSizes?.screenLeftOffset}
}

export default ${key}`

	return (
		<>
			<div><pre>{device}</pre></div>
			<div className={classes.dropzone} style={sizingStyles} {...getRootProps()}>
				<input {...getInputProps({ multiple: false })} />
				{text}
			</div>
			<canvas
				className={classes.canvas}
				ref={canvasRef}
				width={deviceImage?.width || '100%'}
				height={deviceImage?.height || '100%'}
				style={deviceStyles}
			/>
		</>
	)
}

export default Generat0r
