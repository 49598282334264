import { DeviceModel, Device } from '../types'

const AppleIPadPro11WithoutPencil: Device = {
	key: DeviceModel.AppleIPadPro11WithoutPencil,
	brand: 'Apple',
	model: 'iPad Pro 11" (without pencil)',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2788,
	deviceWidth: 2068,
	screenHeight: 2388,
	screenWidth: 1668,
	screenTopOffset: 200,
	screenLeftOffset: 200,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad-pro-11-without-pencil/apple-ipad-pro-11-without-pencil-space-gray.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad-pro-11-without-pencil/apple-ipad-pro-11-without-pencil-silver.png' }
	]
}

export default AppleIPadPro11WithoutPencil
