import { DeviceModel, Device } from '../types'

const AppleMacBookPro13: Device = {
	key: DeviceModel.AppleMacBookPro13,
	brand: 'Apple',
	model: 'MacBook Pro 13"',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2200,
	deviceWidth: 3560,
	screenHeight: 1600,
	screenWidth: 2560,
	screenTopOffset: 300,
	screenLeftOffset: 500,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-macbook-pro-13/apple-macbook-pro-13-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-macbook-pro-13/apple-macbook-pro-13-silver.png' }
	]
}

export default AppleMacBookPro13
