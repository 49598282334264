import { DeviceModel, Device } from '../types'

const AppleMacBookAir: Device = {
	key: DeviceModel.AppleMacBookAir,
	brand: 'Apple',
	model: 'MacBook Air',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2060,
	deviceWidth: 3460,
	screenHeight: 1600,
	screenWidth: 2560,
	screenTopOffset: 230,
	screenLeftOffset: 450,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-macbook-air/apple-macbook-air-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-macbook-air/apple-macbook-air-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-macbook-air/apple-macbook-air-gold.png' }
	]
}

export default AppleMacBookAir
