import { DeviceModel, Device } from '../types'

const AppleIMacPro: Device = {
	key: DeviceModel.AppleIMacPro,
	brand: 'Apple',
	model: 'iMac Pro',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 4810,
	deviceWidth: 5776,
	screenHeight: 2890,
	screenWidth: 5120,
	screenTopOffset: 343,
	screenLeftOffset: 328,
	variants: [
		{ key: 'default', description: '', src: 'devices/apple-imac-pro/apple-imac-pro.png' }
	]
}

export default AppleIMacPro
