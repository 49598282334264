import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DeviceModel, Device } from '../../types'
import history from '../../util/history'
import devices from '../../devices'

const useStyles = makeStyles({
	tab: {
		minWidth: '0',
		textTransform: 'none'
	},
	button: {
		textTransform: 'none'
	}
})

const DeviceSelection: React.FC = () => {
	const classes = useStyles()

	const handleAppleIPhone11Click = useCallback(() => {
		history.push(DeviceModel.AppleIPhone11)
	}, [])

	const handleAppleIMacClick = useCallback(() => {
		history.push(DeviceModel.AppleIMac)
	}, [])

	const handleAppleIPadClick = useCallback(() => {
		history.push(DeviceModel.AppleIPad)
	}, [])

	const handleSamsungGalaxyS20Click = useCallback(() => {
		history.push(DeviceModel.SamsungGalaxyS20)
	}, [])

	const handleSelectDevice = useCallback((event, newValue) => {
		if (newValue) {
			const newDevice = devices.get(newValue.key)
			if (newDevice) {
				history.push(newValue.key)
			}
		}
	}, [])

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Autocomplete
					id="combo-box-demo"
					options={[...devices.values()]}
					getOptionLabel={(option: Device): string => option.model}
					groupBy={(option: Device): string => option.brand}
					style={{ width: '100%' }}
					renderInput={(params): React.ReactNode => <TextField {...params} label="Enter device" variant="filled" />}
					onChange={handleSelectDevice}
				/>
			</Grid>
			<Grid container item alignItems="center">
				<Grid item xs={4}>
					<Divider />
				</Grid>
				<Grid container item xs={4} justify="center">
					<Typography variant="caption" color="textSecondary">or select a popular device</Typography>
				</Grid>
				<Grid item xs={4}>
					<Divider />
				</Grid>
			</Grid>
			<Grid container item direction="row" justify="center" spacing={2}>
				<Grid item xs={12} sm={3}>
					<Button className={classes.button} disableElevation onClick={handleAppleIPhone11Click} fullWidth variant="contained" color="default">iPhone 11</Button>
				</Grid>
				<Hidden xsDown>
					<Grid item sm={3}>
						<Button className={classes.button} disableElevation onClick={handleAppleIPadClick} fullWidth variant="contained" color="default">iPad</Button>
					</Grid>
					<Grid item sm={3}>
						<Button className={classes.button} disableElevation onClick={handleAppleIMacClick} fullWidth variant="contained" color="default">iMac</Button>
					</Grid>
				</Hidden>
				<Grid item xs={12} sm={3}>
					<Button className={classes.button} disableElevation onClick={handleSamsungGalaxyS20Click} fullWidth variant="contained" color="default">Samsung S20</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default DeviceSelection
