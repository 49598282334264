import { DeviceModel, Device } from '../types'

const DellXPS15: Device = {
	key: DeviceModel.DellXPS15,
	brand: 'Dell',
	model: 'XPS 13',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2766,
	deviceWidth: 5192,
	screenHeight: 2160,
	screenWidth: 3840,
	screenTopOffset: 130,
	screenLeftOffset: 677,
	variants: [
		{ key: 'default', description: '', src: 'devices/dell-xps-15/dell-xps-15.png' }
	]
}

export default DellXPS15
