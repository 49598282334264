import { DeviceModel, Device } from '../types'

const AppleMacBook: Device = {
	key: DeviceModel.AppleMacBook,
	brand: 'Apple',
	model: 'MacBook',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 1799,
	deviceWidth: 3064,
	screenHeight: 1440,
	screenWidth: 2304,
	screenTopOffset: 128,
	screenLeftOffset: 380,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-macbook/apple-macbook-space-grey.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-macbook/apple-macbook-gold.png' }
	]
}

export default AppleMacBook
