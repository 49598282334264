import { DeviceModel, Device } from '../types'

const SamsungGalaxyS20: Device = {
	key: DeviceModel.SamsungGalaxyS20,
	brand: 'Samsung',
	model: 'Samsung Galaxy S20',
	searchable: true,
	changeOrientation: true,
	cutCorners: 10,
	deviceHeight: 3600,
	deviceWidth: 1840,
	screenHeight: 3200,
	screenWidth: 1440,
	screenTopOffset: 200,
	screenLeftOffset: 200,
	variants: [
		{ key: 'cosmic-gray', description: 'Cosmic Gray', src: 'devices/samsung-galaxy-s20/samsung-galaxy-s20-cosmic-gray.png' },
		{ key: 'cloud-blue', description: 'Cloud Blue', src: 'devices/samsung-galaxy-s20/samsung-galaxy-s20-cloud-blue.png' },
		{ key: 'cloud-pink', description: 'Cloud Pink', src: 'devices/samsung-galaxy-s20/samsung-galaxy-s20-cloud-pink.png' }
	]
}

export default SamsungGalaxyS20
