import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles({
	container: {
	},
	item: {
		paddingLeft: '8px',
		paddingRight: '8px'
	}
})

const TopBar: React.FC = () => {
	const classes = useStyles()
	return (
		<Grid container className={classes.container} justify="center" alignItems="center">
			<Hidden xsDown>
				<Grid item className={classes.item}>
					<Typography variant="caption" color="textSecondary" component="h1">FREE</Typography>
				</Grid>
				<Divider orientation="vertical" flexItem />
			</Hidden>
			<Grid item className={classes.item}>
				<Typography variant="caption" color="textSecondary" component="h1">Mock-ups made simple & easy</Typography>
			</Grid>
			<Hidden xsDown>
				<Divider orientation="vertical" flexItem />
				<Grid item className={classes.item}>
					<Typography variant="caption" color="textSecondary" component="h1">No e-mail required</Typography>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item className={classes.item}>
					<Typography variant="caption" color="textSecondary" component="h1">Your images are not sent to any server</Typography>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item className={classes.item}>
					<Typography variant="caption" color="textSecondary" component="h1">Device screenshots are made locally by your browser</Typography>
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item className={classes.item}>
					<Typography variant="caption" color="textSecondary" component="h1">No catch</Typography>
				</Grid>
			</Hidden>
		</Grid>
	)
}

export default TopBar
