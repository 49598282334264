import { DeviceModel, Device } from '../types'

const AppleIPad: Device = {
	key: DeviceModel.AppleIPad,
	brand: 'Apple',
	model: 'iPad',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2710,
	deviceWidth: 1920,
	screenHeight: 2160,
	screenWidth: 1620,
	screenTopOffset: 275,
	screenLeftOffset: 150,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad/apple-ipad-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad/apple-ipad-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-ipad/apple-ipad-gold.png' }
	]
}

export default AppleIPad
