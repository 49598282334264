import { DeviceModel, Device } from '../types'

const MicrosoftSurfaceBook: Device = {
	key: DeviceModel.MicrosoftSurfaceBook,
	brand: 'Microsoft',
	model: 'Surface Book',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2456,
	deviceWidth: 4143,
	screenHeight: 2000,
	screenWidth: 3000,
	screenTopOffset: 187,
	screenLeftOffset: 574,
	variants: [
		{ key: 'default', description: '', src: 'devices/microsoft-surface-book/microsoft-surface-book.png' }
	]
}

export default MicrosoftSurfaceBook
