import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import history from './util/history'

import App from './components/App/App'
import Generat0r from './components/Generat0r/Generat0r'

const startApp = (): void => {
	// MUI Theme
	const theme = createMuiTheme({
		palette: {
			primary: blue
		}
	})

	// React Render
	ReactDOM.render(
		<MuiThemeProvider theme={theme}>
			<Router history={history}>
				<Switch>
					<Route exact path="/generat0r" component={Generat0r} />
					<Route exact path="/:deviceKey?" component={App} />
				</Switch>
			</Router>
		</MuiThemeProvider>,
		document.getElementById('root')
	)
}

startApp()
