import { DeviceModel, Device } from '../types'

const AppleWatchSeries544mm: Device = {
	key: DeviceModel.AppleWatchSeries544mm,
	brand: 'Apple',
	model: 'Watch Series 5 44mm',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 968,
	deviceWidth: 568,
	screenHeight: 448,
	screenWidth: 368,
	screenTopOffset: 260,
	screenLeftOffset: 100,
	variants: [
		{ key: 'space-grey-aluminum-black', description: 'Space Grey Aluminum + Black', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-space-grey-aluminum-black.png' },
		{ key: 'gold-aluminum-pink-sand', description: 'Gold Aluminum + Pink Sand', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-gold-aluminum-pink-sand.png' },
		{ key: 'gold-stainless-steel-stone', description: 'Gold Stainless Steel + Stone', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-gold-stainless-steel-stone.png' },
		{ key: 'silver-aluminum-clementine', description: 'Silver Aluminum + Clementine', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-silver-aluminum-clementine.png' },
		{ key: 'space-black-stainless-steel-alaskan-blue', description: 'Space Black Stainless Steel + Alaskan Blue', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-space-black-stainless-steel-alaskan-blue.png' },
		{ key: 'space-black-titanium-pine-green', description: 'Space Black Titanium + Pine Green', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-space-black-titanium-pine-green.png' },
		{ key: 'stainless-steel-lemon-cream', description: 'Stainless Steel + Lemon Cream', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-stainless-steel-lemon-cream.png' },
		{ key: 'titanium-red', description: 'Titanium + Red', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-titanium-red.png' },
		{ key: 'white-ceramic-white', description: 'White Ceramic + White', src: 'devices/apple-watch-series-5-44mm/apple-watch-series-5-44mm-white-ceramic-white.png' }
	]
}

export default AppleWatchSeries544mm
