import { DeviceModel, Device } from '../types'

const AppleIMac: Device = {
	key: DeviceModel.AppleIMac,
	brand: 'Apple',
	model: 'iMac',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2351,
	deviceWidth: 2788,
	screenHeight: 1440,
	screenWidth: 2560,
	screenTopOffset: 155,
	screenLeftOffset: 114,
	variants: [
		{ key: 'default', description: '', src: 'devices/apple-imac/apple-imac.png' }
	]
}

export default AppleIMac
