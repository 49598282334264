import { DeviceModel, Device } from '../types'

const AppleIPadMini: Device = {
	key: DeviceModel.AppleIPadMini,
	brand: 'Apple',
	model: 'iPad Mini',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2708,
	deviceWidth: 1836,
	screenHeight: 2048,
	screenWidth: 1536,
	screenTopOffset: 330,
	screenLeftOffset: 150,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-ipad-mini/apple-ipad-mini-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-ipad-mini/apple-ipad-mini-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-ipad-mini/apple-ipad-mini-gold.png' }
	]
}

export default AppleIPadMini
