import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Device } from '../../types'

const useStyles = makeStyles({
	loadingContainer: {
		width: '100%',
		height: '100%'
	}
})

interface WhiteBackgroundProps {
	device: Device,
	aspectRatio: number,
	zoom: number,
	rotated: boolean,
	isLoading: boolean
}

const WhiteBackground: React.FC<WhiteBackgroundProps> = ({ device, aspectRatio, zoom, rotated, isLoading }) => {
	const classes = useStyles()

	if (!isLoading) {
		return null
	}

	const { screenHeight } = device
	let size = screenHeight * zoom
	if (rotated) {
		size = device.screenWidth * zoom
	}

	size *= aspectRatio
	size /= 4

	return (
		<Grid className={classes.loadingContainer} container justify="center" alignItems="center">
			<CircularProgress size={size} />
		</Grid>
	)
}

export default WhiteBackground
