import { DeviceModel, Device } from '../types'

const SamsungGalaxyS20: Device = {
	key: DeviceModel.SamsungGalaxyS10,
	brand: 'Samsung',
	model: 'Samsung Galaxy S10',
	searchable: true,
	changeOrientation: true,
	cutCorners: 10,
	deviceHeight: 3340,
	deviceWidth: 1640,
	screenHeight: 3040,
	screenWidth: 1440,
	screenTopOffset: 150,
	screenLeftOffset: 100,
	variants: [
		{ key: 'prism-black', description: 'Prism Black', src: 'devices/samsung-galaxy-s10/samsung-galaxy-s10-prism-black.png' },
		{ key: 'prism-white', description: 'Prism White', src: 'devices/samsung-galaxy-s10/samsung-galaxy-s10-prism-white.png' },
		{ key: 'prism-blue', description: 'Prism Blue', src: 'devices/samsung-galaxy-s10/samsung-galaxy-s10-prism-blue.png' },
		{ key: 'prism-green', description: 'Prism Green', src: 'devices/samsung-galaxy-s10/samsung-galaxy-s10-prism-green.png' },
		{ key: 'flamingo-pink', description: 'Flamingo Pink', src: 'devices/samsung-galaxy-s10/samsung-galaxy-s10-flamingo-pink.png' }
	]
}

export default SamsungGalaxyS20
