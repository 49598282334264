import { DeviceModel, Device } from '../types'

const AppleIPhone8: Device = {
	key: DeviceModel.AppleIPhone8,
	brand: 'Apple',
	model: 'iPhone 8',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 1894,
	deviceWidth: 950,
	screenHeight: 1334,
	screenWidth: 750,
	screenTopOffset: 280,
	screenLeftOffset: 100,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-iphone-8/apple-iphone-8-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-iphone-8/apple-iphone-8-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-iphone-8/apple-iphone-8-gold.png' }
	]
}

export default AppleIPhone8
