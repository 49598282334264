export enum DeviceModel {
	AppleIMac = 'apple-imac',
	AppleIMacPro = 'apple-imac-pro',
	AppleIPad = 'apple-ipad',
	AppleIPadAir = 'apple-ipad-air',
	AppleIPadMini = 'apple-ipad-mini',
	AppleIPadPro11WithoutPencil = 'apple-ipad-pro-11-without-pencil',
	AppleIPadPro11WithPencil = 'apple-ipad-pro-11-with-pencil',
	AppleIPadPro13WithoutPencil = 'apple-ipad-pro-13-without-pencil',
	AppleIPadPro13WithPencil = 'apple-ipad-pro-13-with-pencil',
	AppleIPhone11 = 'apple-iphone-11',
	AppleIPhone11Pro = 'apple-iphone-11-pro',
	AppleIPhone11ProMax = 'apple-iphone-11-pro-max',
	AppleIPhone8 = 'apple-iphone-8',
	AppleIPhone8Plus = 'apple-iphone-8-plus',
	AppleMacBook = 'apple-macbook',
	AppleMacBookAir = 'apple-macbook-air',
	AppleMacBookPro13 = 'apple-macbook-pro-13',
	AppleMacBookPro16 = 'apple-macbook-pro-16',
	AppleProDisplayXDR = 'apple-pro-display-xdr',
	AppleThunderboltDisplay = 'apple-thunderbolt-display',
	AppleWatchSeries540mm = 'apple-watch-series-5-40mm',
	AppleWatchSeries544mm = 'apple-watch-series-5-44mm',
	DellUltraSharp = 'dell-ultrasharp',
	DellXPS13 = 'dell-xps-13',
	DellXPS15 = 'dell-xps-15',
	MicrosoftLumia950 = 'microsoft-lumia-950',
	MicrosoftSurfaceBook = 'microsoft-surface-book',
	MicrosoftSurfacePro4 = 'microsoft-surface-pro-4',
	SamsungGalaxyS10 = 'samsung-galaxy-s10',
	SamsungGalaxyS20 = 'samsung-galaxy-s20',
	SamsungGalaxyS20Ultra = 'samsung-galaxy-s20-ultra',
	SonySmartWatch3 = 'sony-smartwatch-3',
	SonyW850C = 'sony-w850c',
}

export interface Variant {
	key: string,
	description: string,
	src: string
}

export interface Device {
	key: DeviceModel,
	brand: string,
	model: string,
	searchable: boolean,
	changeOrientation: boolean,
	cutCorners?: number,
	deviceHeight: number,
	deviceWidth: number,
	screenHeight: number,
	screenWidth: number,
	screenTopOffset: number,
	screenLeftOffset: number,
	variants: Variant[]
}
