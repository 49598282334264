import { DeviceModel, Device } from '../types'

const MicrosoftLumia950: Device = {
	key: DeviceModel.MicrosoftLumia950,
	brand: 'Microsoft',
	model: 'Lumia 950',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 3215,
	deviceWidth: 1642,
	screenHeight: 2560,
	screenWidth: 1440,
	screenTopOffset: 366,
	screenLeftOffset: 93,
	variants: [
		{ key: 'black', description: 'Black', src: 'devices/microsoft-lumia-950/microsoft-lumia-950-black.png' },
		{ key: 'white', description: 'White', src: 'devices/microsoft-lumia-950/microsoft-lumia-950-white.png' }
	]
}

export default MicrosoftLumia950
