import { DeviceModel, Device } from '../types'

const AppleIPhone8Plus: Device = {
	key: DeviceModel.AppleIPhone8Plus,
	brand: 'Apple',
	model: 'iPhone 8 Plus',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 3008,
	deviceWidth: 1642,
	screenHeight: 2208,
	screenWidth: 1242,
	screenTopOffset: 400,
	screenLeftOffset: 200,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-iphone-8-plus/apple-iphone-8-plus-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-iphone-8-plus/apple-iphone-8-plus-silver.png' },
		{ key: 'gold', description: 'Gold', src: 'devices/apple-iphone-8-plus/apple-iphone-8-plus-gold.png' }
	]
}

export default AppleIPhone8Plus
