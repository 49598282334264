import { DeviceModel, Device } from '../types'

const AppleMacBookPro16: Device = {
	key: DeviceModel.AppleMacBookPro16,
	brand: 'Apple',
	model: 'MacBook Pro 16"',
	searchable: true,
	changeOrientation: false,
	deviceHeight: 2520,
	deviceWidth: 4072,
	screenHeight: 1920,
	screenWidth: 3072,
	screenTopOffset: 300,
	screenLeftOffset: 500,
	variants: [
		{ key: 'space-grey', description: 'Space Grey', src: 'devices/apple-macbook-pro-16/apple-macbook-pro-16-space-grey.png' },
		{ key: 'silver', description: 'Silver', src: 'devices/apple-macbook-pro-16/apple-macbook-pro-16-silver.png' }
	]
}

export default AppleMacBookPro16
