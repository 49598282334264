import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useDropzone } from 'react-dropzone'
import { Device } from '../../types'

const useStyles = makeStyles({
	dropzone: {
		position: 'absolute',
		zIndex: 3,
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		outline: 'none',
		cursor: 'pointer',
		overflow: 'hidden'
	}
})

interface DropzoneProps {
	device: Device,
	aspectRatio: number,
	zoom: number,
	file: any,
	setFile: (file: any) => void,
	rotated: boolean,
	isLoading: boolean
}

const App: React.FC<DropzoneProps> = ({ device, aspectRatio, zoom, file, setFile, rotated, isLoading }) => {
	const classes = useStyles()
	const onDrop = useCallback((acceptedFiles) => {
		const [acceptedFile] = acceptedFiles
		acceptedFile.src = URL.createObjectURL(acceptedFile)
		setFile(acceptedFile)
	}, [setFile])
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	if (isLoading) {
		return null
	}

	let { screenWidth, screenHeight, screenTopOffset, screenLeftOffset } = device
	screenWidth *= zoom
	screenHeight *= zoom
	screenTopOffset *= zoom
	screenLeftOffset *= zoom
	if (rotated) {
		screenWidth = device.screenHeight * zoom
		screenHeight = device.screenWidth * zoom
		screenTopOffset = device.screenLeftOffset * zoom
		screenLeftOffset = device.screenTopOffset * zoom
	}

	const sizingStyles = {
		width: screenWidth * aspectRatio,
		height: screenHeight * aspectRatio,
		marginTop: screenTopOffset * aspectRatio,
		marginLeft: screenLeftOffset * aspectRatio
	}

	let text
	if (!file) {
		text = (
			<Typography component="span" style={{ margin: '8px', textAlign: 'center', width: '100%', overflow: 'hidden', fontSize: '2vh' }} color="textSecondary">
				<p>Drag ‘n’ drop your image, or click here to select</p>
				<p>The recommended size is {screenWidth} × {screenHeight} pixels</p>
			</Typography>
		)
	}

	return (
		<div className={classes.dropzone} style={sizingStyles} {...getRootProps()}>
			<input {...getInputProps({ multiple: false })} />
			{text}
		</div>
	)
}

export default App
