import { DeviceModel, Device } from '../types'

const MicrosoftSurfacePro4: Device = {
	key: DeviceModel.MicrosoftSurfacePro4,
	brand: 'Microsoft',
	model: 'Surface Pro 4',
	searchable: true,
	changeOrientation: true,
	deviceHeight: 2224,
	deviceWidth: 3216,
	screenHeight: 1824,
	screenWidth: 2736,
	screenTopOffset: 200,
	screenLeftOffset: 240,
	variants: [
		{ key: 'default', description: '', src: 'devices/microsoft-surface-pro-4/microsoft-surface-pro-4.png' }
	]
}

export default MicrosoftSurfacePro4
