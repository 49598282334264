import React, { useEffect, RefObject } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Device, Variant } from '../../types'

const useStyles = makeStyles({
	canvas: {
		position: 'absolute',
		zIndex: 2
	}
})

interface DeviceCanvasProps {
	canvasRef: RefObject<HTMLCanvasElement>,
	device: Device,
	variant: Variant,
	aspectRatio: number,
	zoom: number,
	file: any,
	rotated: boolean,
	isLoading: boolean,
	setIsLoading: (isLoading: boolean) => void
}

const drawDevice = async (ctx: CanvasRenderingContext2D, device: Device, variant: Variant, zoom: number, rotated: boolean): Promise<void> => new Promise((resolve, reject) => {
	const img = new Image()

	if (ctx) {
		try {
			img.onload = (): void => {
				const rotation = rotated ? 90 : 0
				ctx.save()
				ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
				ctx.rotate((rotation * Math.PI) / 180)
				ctx.drawImage(img, (-device.deviceWidth * zoom) / 2, (-device.deviceHeight * zoom) / 2, device.deviceWidth * zoom, device.deviceHeight * zoom)
				ctx.restore()
				resolve()
			}
			img.src = variant.src
		} catch (error) {
			reject()
		}
	} else {
		reject()
	}
})

const drawScreen = async (ctx: CanvasRenderingContext2D, device: Device, zoom: number, file: any, rotated: boolean): Promise<void> => new Promise((resolve, reject) => {
	const img = new Image()

	if (ctx) {
		try {
			img.onload = (): void => {
				let { screenWidth, screenHeight, screenTopOffset, screenLeftOffset, cutCorners = 0 } = device
				screenWidth *= zoom
				screenHeight *= zoom
				screenTopOffset *= zoom
				screenLeftOffset *= zoom
				cutCorners *= zoom
				if (rotated) {
					screenWidth = device.screenHeight * zoom
					screenHeight = device.screenWidth * zoom
					screenTopOffset = device.screenLeftOffset * zoom
					screenLeftOffset = device.screenTopOffset * zoom
				}

				ctx.drawImage(img, screenLeftOffset, screenTopOffset, screenWidth, screenHeight)

				if (cutCorners) {
					ctx.clearRect(screenLeftOffset, screenTopOffset, cutCorners, cutCorners) // Cut left top corner
					ctx.clearRect(screenLeftOffset + screenWidth - cutCorners, screenTopOffset, cutCorners, cutCorners) // Cut right top corner
					ctx.clearRect(screenLeftOffset, screenTopOffset + screenHeight - cutCorners, cutCorners, cutCorners) // Cut left bottom corner
					ctx.clearRect(screenLeftOffset + screenWidth - cutCorners, screenTopOffset + screenHeight - cutCorners, cutCorners, cutCorners) // Cut left bottom corner
				}

				resolve()
			}
			img.src = file.src
		} catch (error) {
			reject()
		}
	} else {
		reject()
	}
})

const clear = (ctx: CanvasRenderingContext2D): void => ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

const DeviceCanvas: React.FC<DeviceCanvasProps> = ({ canvasRef, device, variant, aspectRatio, zoom, file, rotated, isLoading, setIsLoading }) => {
	const classes = useStyles()

	useEffect(() => {
		let isMounted = true
		const draw = async (): Promise<void> => {
			setIsLoading(true)
			const ctx = canvasRef?.current?.getContext('2d')
			if (ctx) {
				clear(ctx)
				if (file) {
					await drawScreen(ctx, device, zoom, file, rotated)
				}
				await drawDevice(ctx, device, variant, zoom, rotated)
			}
			if (isMounted) {
				setIsLoading(false)
			}
		}
		const ctx = canvasRef?.current?.getContext('2d')
		if (ctx) {
			clear(ctx)
		}
		draw()

		// If this component is unmounted, then another device is being loaded, so this component should not unset the loading indicator
		return (): void => { isMounted = false }
	}, [canvasRef, device, variant, zoom, file, rotated, setIsLoading])

	let { deviceWidth, deviceHeight } = device
	deviceWidth *= zoom
	deviceHeight *= zoom
	if (rotated) {
		deviceWidth = device.deviceHeight * zoom
		deviceHeight = device.deviceWidth * zoom
	}

	const deviceStyles = {
		width: deviceWidth * aspectRatio,
		height: deviceHeight * aspectRatio,
		visibility: isLoading ? 'hidden' : 'visible' as 'hidden' // wtf typescript
	}

	return (
		<canvas
			className={classes.canvas}
			ref={canvasRef}
			width={deviceWidth}
			height={deviceHeight}
			style={deviceStyles}
		/>
	)
}

export default DeviceCanvas
